@include media-breakpoint-down(md) {
  .dropdown-m {
    width: 100%;
  }
  .competitor-comparison-table {
    font-size: 0.8rem;
  }

}

@include media-breakpoint-up(lg) {
  .competitor-comparison-table {
    font-size: 1rem;
  }
}

.dropdown-cart {
  width: 300px;
}

#sl-map-container {
  height: 100%;
  min-height: 450px;
}

.user-marker-selector {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  background-color: white;
}

#showUserMarkerSelector {
  padding: 10px;
}

.may-also-like {
  position: relative;
  .sticker {
    position: absolute;
    top: 0;
    left: 5px;
  }
}

.jumbotron {
  .featured-text {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: $gray-700;
  height: $carousel-control-width;
  margin-top: -(($carousel-control-width) / 2);
  opacity: 0.9;
  top: 50%;
  width: $carousel-control-width;
}


.share-fixed{
  position:fixed;
  top:182px;
  left:0;
  z-index:9;
  transition: all 0.5s ease;
  transform: translate(-182px, 0px);

  a.main-link{
    span.icon{
      display:flex;
      align-content: center;
      justify-content: center;
      float:right;
      width:50px;
      height:50px;
      box-shadow: 0 0 3px 0 #cacaca;
      svg{
        align-self: center;
        fill: white;
        width: auto;
        height: 30px;
      }
    }

    span.icon.facebook{}

    span.icon.twitter{}

    span.icon.linkedin{}

    span.icon.share{}

    span.line{
      display:inline-block;
      float:right;
      width:6px;
      height:50px;
      border-left:3px solid $white;
      background-color:$primary;
      transform: translate(3px, 0px);
      transition: transform 0.5s ease;
    }
    label{
      display:inline-block;
      margin-right:10px;
      font-size:15px;
      font-weight:normal;
      letter-spacing:1.3px;
      cursor:pointer;
      width:167px;
      text-align: right;
    }
    padding-right:10px;
    color:$primary;
    display:block;
    background-color:white;
    padding:0px;
    margin-bottom:5px;
    text-transform:uppercase;
    line-height:50px;
    height:50px;
    box-shadow: 0 0 3px 0 #cacaca;
    overflow:hidden;
    cursor:pointer;
    transition: .4s;

    &:hover{
      box-shadow: 0 0 13px 0 #cacaca;
      transition: .2s;
    }

  }

  &:hover{
    transform: translate(0px, 0px);
    a{
      span.line{
        transform: translate(-179px, 0px);
      }
    }
  }

}

.breadcrumbs__share {
  cursor: pointer;
  position: relative;
  text-align: right;
  z-index: 5;

  span.line{
    display:inline-block;
    float: left;
    width:4px;
    height:100%;
    border-left:1px solid $white;
    background-color:$primary;
    transition: transform 0.5s ease;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  span.icon{
    display:inline-block;
    float:right;
    width:50px;
    height:50px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: initial !important;
    margin-right:10px;
    box-shadow: 0 0 3px 0 #cacaca;
    position: absolute;
    top: -50px;
    right: -10px;
  }

}

.breadcrumbs__share p, .social__share p {

  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin: 0;
  z-index: 10;
  font-size: 13px;
  margin-top: 3px;
  color:#363c3d;
}

.breadcrumbs__share p span, .social__share p span {
  display: none
}

@media only screen and (min-width:992px) {
  .breadcrumbs__share p span, .social__share p span {
    display: block
  }
}

.breadcrumbs__share p svg, .social__share p svg {
  stroke: #363c3d;
  position: absolute;
  left: -24px;
  top: -6px;
  width: 36px;
  height: 36px;
  stroke-width: 1.5px
}

@media only screen and (min-width:992px) {
  .breadcrumbs__share p svg  {
    left: -39px
  }
}

.breadcrumbs__share i, .social__share i {
  padding-right: 8px;
  position: absolute;
  top: 0;
  font-size: 22px;
  left: -30px
}

.breadcrumbs__share-list, .social__share-list {
  display: none;
  text-align: left;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .16);
  background: $white;
  padding: 20px;
  margin-top:-5px;
}

.breadcrumbs__share-list li, .social__share-list li {
  list-style-type: none;
  padding-bottom: 15px;
  font-size: 14px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.breadcrumbs__share-list li img,
.breadcrumbs__share-list li svg,
.social__share-list li img,
.social__share-list li svg,
{
  width: 26px;
  height: 26px;
  margin-right: 5px
}

.breadcrumbs__share-list li span, .social__share-list li span {
  padding-top: 3px
}

.breadcrumbs__share-list p, .social__share-list p {
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: none;
  letter-spacing: unset;
  font-size: 14px;
  color:#363c3d;
}

.breadcrumbs__share-list input, .social__share-list input {
  width: 100%;
  border: 1px solid #000;
  outline: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
  font-size: 12px;
  color: #004c7c
}

.breadcrumbs__share-list a, .social__share-list a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  color:#363c3d;
}

.breadcrumbs__share-list.active, .social__share-list.active {
  display: block
}

.breadcrumbs__list {
  margin: 0;
  display: none
}

.social__share-list{
  display: block;
  box-shadow: none;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.facebook-color {
  background-color: #4267B2;
}

.twitter-color {
  background-color: #1da1f2;
}

.linkedin-color {
  background-color: #0076b2;
}

.share-all-color {
  background-color: #f1f1f1;
}

.social-share-bar {
  & > a {
    position: relative;
    text-decoration: none;
    width: auto;
    height: 32px;
    &:after {
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    &:hover:after {
      background-color: rgba(0,0,0,.1);
    }
  }
  .icon-share {
    width: 32px;
    height: 32px;
    background-color: rgba(0,0,0,.1);
    color: white;
    svg {
      fill: white;
      height: 16px;
      width: inherit;
    }
  }
}

.alert-flex {
  display: flex;
}

/*CookieBot*/

#CybotCookiebotDialog div {
  font-family: "Hanken Grotesk",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}



#CybotCookiebotDialogBodyButtonDecline {
 color: white !important;
}